import React, { Component } from 'react';
import { css } from '@emotion/core';
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 100px auto;
  width: 100px;
  height: 100px;
`;

export class Loading extends Component {
    render() {
        return (
            <div className="loading">
                <ClipLoader
                    css={override}
                    size={150}
                    color={"#FBCF57"}
                />
            </div>
        )
    }
}

export default Loading