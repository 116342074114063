import React, { Component } from 'react';
import Slider from "react-slick";
import flower from "../../assets/flower.png";

export class RecentieSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinte: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        }
        return (
            <div id="recensies">
                <div className="container">
                    <h2 className="title recensie-slider__title">Recensies</h2>
                </div>
                <div className="recensie-slider">
                    <Slider {...settings}>
                        <div className="recensieContainer">
                            <div className="recensie">
                                <img src={flower} class="recensie__flower" alt="flower"/>
                                <p className="recensie__text">
                                    <span class="recensie__quote">"</span>Marlon heeft me ontzettend goed geholpen en naar me geluisterd. Ik voelde me helemaal op mijn gemak bij haar. Ze gaf me helpende handvatten waarmee ik verder kan. Het is allemaal heel persoonlijk gegaan. Er was veel aandacht voor wat ik nodig had!<span class="recentie__quote">"</span>
                                </p>
                            </div>
                        </div>
                        <div className="recensieContainer">
                            <div className="recensie">
                                <img src={flower} class="recensie__flower" alt="flower"/>
                                <p className="recensie__text">
                                    <span class="recensie__quote">"</span>Ik heb me erg op mijn gemak gevoeld bij Marlon. Ze nam bij elke sessie echt de tijd. De oefenningen die ze gebruikte hadden vaak beeldende onderdelen en dit werkte goed voor mij en hebben mij echt geholpen<span class="recentie__quote">"</span>
                                </p>
                            </div>
                        </div>
                        <div className="recensieContainer">
                            <div className="recensie">
                                <img src={flower} class="recensie__flower" alt="flower" />
                                <p className="recensie__text">
                                    <span class="recensie__quote">"</span>Ik kwam bij Marlon omdat het al een paar maanden niet goed met me ging. 
                                    In de verschillende gesprekken leerde ik beter om over mijn gevoelens te praten. Tijdens het traject ben ik gaan inzien waar bepaalde gevoelens vandaan komen.  
                                    Aan het einde van het traject voelde ik me een stuk beter in mijn vel en ik ben persoonlijk gegroeid.
                                    <span class="recensie__quote">"</span>
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>   
            </div>
        )
    }
}

export default RecentieSlider