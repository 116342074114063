import React, { useEffect, useState } from 'react';
import logo from "../../assets/logo.png";
import flower from "../../assets/flower.png";
import { Link } from 'react-router-dom';

function Navigation() {
    const [windowDimension, setWindowDimension] = useState("");
    const [scrolled, setScrolled] = useState(false);
    const isMobile = windowDimension <= 640;
    const [clicked, setClicked] = useState(false);
    
    let root = document.getElementById("root");

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, [])

    useEffect(() => {
        function handleResize(){
            setWindowDimension(window.innerWidth)
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize)
    }, [])
    
    function handleScroll() {
        if(window.pageYOffset >= 250) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    if(isMobile) {
        window.addEventListener("scroll", handleScroll)
        root.style.position = "relative";

        if(clicked) {
            document.body.style.overflow = "hidden";
        }  else {
            document.body.style.overflow = "auto";
        }
        return(
            <>
                <div className={"hamburger__wrapper " + ((scrolled && !clicked) ? "hamburger__wrapper--yellow" : "")}>
                    <div className={"hamburger " + (clicked ? "hamburger--active" : "")} onClick={()=> setClicked(!clicked)}>
                        <div className="hamburger__item hamburger__item--top"></div>
                        <div className="hamburger__item hamburger__item--middle"></div>
                        <div className="hamburger__item hamburger__item--bottom"></div>
                    </div>
                </div>
                
                <div className={"navigation navigation--mobile "  + (clicked ? "navigation--active" : "")}>
                    <img src={logo} alt="logo" className="navigation__logo" />
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/#overmij">Over mij</a>
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/#werkwijze">Werkwijze</a>
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/#tarieven">Tarieven</a>
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/#recensies">Recensies</a>
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/#contact">Contact</a>
                    <a className="navigation__item" onClick={()=> setClicked(!clicked)} href="/inspiratie">Inspiratie</a>
                    <img src={flower} alt="logo" className="navigation__flower" />
                </div>
            </>
        )
    } else {
        root.style.position = "static";

        return (
            <div className="navigation">
                <Link to="/#overmij" className="navigation__item">Over mij</Link>
                <Link to="/#werkwijze" className="navigation__item">Werkwijze</Link>
                <Link to="/#tarieven" className="navigation__item">Tarieven</Link>
                <Link to="/#recensies" className="navigation__item">Recensies</Link>
                <Link to="/#recensies" className="navigation__item">Contact</Link>
                <Link to="/inspiratie" className="navigation__item">Inspiratie</Link>
            </div>
        )
    }
}

export default Navigation