import React, { Component } from 'react';
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Contact from "../Contact/Contact"
import Loading from "../Loading/Loading";

export class InspirationPage extends Component {
    state = {
        posts: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get('https://admin.marloncoachingentherapie.nl/wp-json/wp/v2/pages/63')
            .then(res => {
                this.setState({
                    posts: res.data,
                    isLoaded: true
                })
            }
            )
            .catch(err => console.log(err))
    }

    render() {
        const { posts, isLoaded } = this.state;
        return (
            <>
                <Header /> 
                <div className="inspiration-cards-container">
                    <h1 className="title title--inspiration">Inspiratie</h1>
                    {isLoaded
                        ? <div dangerouslySetInnerHTML={{ __html: posts.content.rendered }}></div>
                        : <Loading />
                    }
                </div>
                <Contact />
                <Footer />
            </>
        )
    }
}

export default InspirationPage