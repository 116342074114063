import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from './Components/Homepage/Homepage';
import InspirationPage from './Components/InspirationPage/InspirationPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/inspiratie" component={InspirationPage} />
      </Switch>
    </Router>
  );
}

export default App;
