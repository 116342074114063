import React, { Component } from 'react';
import instagram from "../../assets/instagram.svg";
import email from "../../assets/email.svg";
import facebook from "../../assets/facebook2.svg";
import phone from "../../assets/phone.svg";

export class Contact extends Component {
    render() {
        return (
            <div className="contact" id="contact">
                <div className="container container--contact contact">
                    <h2 className="title">Contact</h2>
                    <div className="contact__item">
                        <img src={email} alt="mail icon" className="contact__icon"/>
                        <div className="contact-content">
                            <span className="contact-content__title">Email</span>
                            <a href="mailto:info@marloncoachingentherapie.com" className="contact-content__text">info@marloncoachingentherapie.nl</a>
                        </div>
                    </div>
                    <div className="contact__item">
                        <img src={phone} alt="phone icon" className="contact__icon"/>
                        <div className="contact-content">
                            <span className="contact-content__title">Telefoon</span>
                            <a href="tel:31634194177" className="contact-content__text">06 34194177</a>
                        </div>
                    </div>
                    <div className="contact__item">
                        <img src={instagram} alt="instagram icon" className="contact__icon"/>
                        <div className="contact-content">
                            <span className="contact-content__title">Instagram</span>
                            <a href="https://www.instagram.com/debruijnmarlon/" className="contact-content__text">@debruijnmarlon</a>
                        </div>
                    </div>
                    <div className="contact__item">
                        <img src={facebook} alt="facebook icon" className="contact__icon"/>
                        <div className="contact-content">
                            <span className="contact-content__title">Facebook</span>
                            <a href="https://nl-nl.facebook.com/marlon.vanonselen" className="contact-content__text">@marlon.vanonselen</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact