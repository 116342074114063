import React, { Component } from 'react';
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Contact from "../Contact/Contact"
import Loading from "../Loading/Loading";
import RecentieSlider from '../RecensieSlider/RecensieSlider';

export class Homepage extends Component {
    state = {
        posts: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get('https://admin.marloncoachingentherapie.nl/wp-json/wp/v2/pages/7')
            .then(res => {
                this.setState({
                    posts: res.data,
                    isLoaded: true
                })
            }
            )
            .catch(err => console.log(err))
    }

    render() {
        const { posts, isLoaded } = this.state;
        console.log(this.state)
        return (
            <>
                <Header />
                {isLoaded
                    ? <div dangerouslySetInnerHTML={{ __html: posts.content.rendered }}></div>
                    : <Loading />
                }
                <RecentieSlider />
                <Contact />
                <Footer />
            </>
        )
    }
}

export default Homepage