import React, { Component } from 'react';
import flower from "../../assets/flower.png"

export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container container--footer footer__content">
                    <img src={flower} alt="flower" className="footer__image" />
                    <div className="footer-address">
                        <span className="footer__address-span footer__span">Marlon de Bruijn-van Onselen</span>
                        <span className="footer__address-span footer__span">Prins Clausstraat 3</span>
                        <span className="footer__address-span footer__span">2691CR 's-Gravenzande</span>
                    </div>
                    <div className="footer__kvk">
                        <span className="footer__kvk-span footer__span">KVK: 80400655</span>
                        <span className="footer__kvk-span footer__span">BTW: NL003440946B70</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer