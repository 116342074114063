import React, { Component } from 'react';
import logo from "../../assets/logo.png";
import flower from "../../assets/flower.png";
import Navigation from "../Navigation/Navigation"

export class Header extends Component {
    render() {
        return (
            <div className="header">
                <Navigation />
                <div className="container container--header header__content">
                    <img src={logo} alt="logo" className="header__logo" />
                    <img src={flower} alt="flower" className="header__image" />
                </div>
            </div>
        )
    }
}

export default Header